import React, { useState, useEffect } from 'react';
import {
    TextField,
    Box,
    Grid,
    CardHeader,
    IconButton,
    Typography,
    Button,
    Card,
    CardContent,
    Avatar,
    Snackbar,
} from '@mui/material';
//import makeStyles from '@mui/styles/makeStyles';
//import ProgressShowBox from '../Setup/ProgressShowBox';
//import EditIcon from '@mui/icons-material/Edit';
import { red } from '@mui/material/colors';
import {
    removeEmptyStringElements,
    UpdateData,
    FindCategoryIcon
} from '../utils/CommonGraphql';
import { useSelector } from 'react-redux';
import { createSvcbotDb } from '../graphql/mutations';
import { API, graphqlOperation } from "aws-amplify";
import HoverRating from './Rating';


var currentUnixTime = new Date();
var currentTime = currentUnixTime.toISOString();

const FeedBackModule = (props) => {
    //  const classes = useStyles();
    const UserData = useSelector(state => state.UserData);
    const [activeStep, setActiveStep] = useState(-1);
    const initialState = {
        StepChecked: [],
        ServiceSteps: [],
        CurrentStep: 0,
        Communication: 0,
        WorkmanShip: 0,
        Recommend: 0,
        Notes: "",
        Result: "",
        avatar: "",
        field1: "",
        FeedBackTime: ""
    }
    const [state, setState] = useState(initialState);
    //  const [FormGroupSelectFound, SetFormGroupSelectFound] = useState();

    useEffect(() => {
        console.log("FeedBackModule.props ", props);
        //CloseDrawer UpdateEvent  SREvent
        if (props.SREvent) {
            let SREvent = props.SREvent;
            let FeedBack = {};
            let readOnly = true;
            let ServiceSteps = SREvent.ProgressList;

            if (UserData.CustomerType === "Company") {
                readOnly = false;
            }

            if (SREvent.FeedBack) {
                FeedBack = SREvent.FeedBack;
            }

            console.log("FeedBackModule.ServiceSteps ", ServiceSteps);

            setState(prevState => ({
                ...prevState,
                SREvent: SREvent,
                ServiceSteps: ServiceSteps,
                readOnly: readOnly,
                ...FeedBack
            }));
        }

    }, [])

    //
    const KeyArray = (e, name) => {
        setState(prevState => ({
            ...prevState,
            [name]: e
        }));
    };

    const UpdateDatabase = (event) => {

        const progresstiny = {
            title: event.title,
            field1: event.title,
            status: event.status,
            Feedback: event.Feedback,
            ServiceRequest: event.ServiceRequest,
            ProgressHistory: event.ProgressHistory,
            date: currentTime,
            Progress: event.Progress,
            PostId: event.PostId
        };

        // const progresstinyJSON = removeEmptyStringElements(progresstiny);

        const ValueToUpdate = {
            pk1: `Post::${event.PostId}`,
            sk1: `Post::Progress::${event.ServiceRequest.sk1}`,
            gsi1Sk2: currentTime,
            gsi2Sk2: currentTime,
            dataJSON: JSON.stringify(progresstiny)
        };

        const FeedBack = {
            pk1: `Post::${event.PostId}`,
            sk1: `Post::FeedBack::${event.ServiceRequest.sk1}`,
            gsi1Pk2: `Post::FeedBack::${event.posterId.CompanyId}`,
            gsi1Sk2: currentTime,
            gsi2Pk2: `Post::FeedBack::${event.ContractorData.ContractorId}`,
            gsi2Sk2: currentTime,
            dataJSON: JSON.stringify(progresstiny)
        };

        console.log("FeedBackModule.progresstiny", progresstiny)

        UpdateData(ValueToUpdate);

        API.graphql(graphqlOperation(createSvcbotDb, { input: FeedBack })).then(
            (results) => {
                console.log("FeedBackModule.db", results);
                if (props.CloseDrawer) {
                    props.CloseDrawer();
                }

            }
        );
    }

    const ProcessMessage = (response) => {

        if (state.Notes !== "") {
            let ServiceSteps = state.ServiceSteps;
            let SREvent = state.SREvent;

            let totalChecked = ServiceSteps.indexOf(x => x.key === "workaccepted");

            if (totalChecked > -1 && response === "Accepted") {

                ServiceSteps[totalChecked].stepcomplete = true;
                ServiceSteps[totalChecked].date = currentTime;
                SREvent.ProgressList = ServiceSteps;
                SREvent.Progress = 100;
            }

            SREvent.status = response;

            let LocalTime = new Date(currentTime).toLocaleString()

            let FeedBack = {
                Communication: state.Communication,
                WorkmanShip: state.WorkmanShip,
                Recommend: state.Recommend,
                Notes: state.Notes,
                Result: response,
                avatar: UserData.avatar,
                field1: UserData.field1,
                FeedBackTime: LocalTime
            }

            SREvent.FeedBack = FeedBack;

            let EventData = SREvent.EventData;
            let NewEventData = [];
            let TotalLength = EventData.length;
            let NewEventAdd = {
                PhotoService: [],
                Documents: [],
                key: state.NewId,
                Body: `${response} - ${state.Notes}`,
                TimeStamp: currentTime,
                SenderName: UserData.field1,
                PosterId: UserData.key,
                avatar: UserData.avatar,
                Action: props.UpdateCategory,
                SenderCompany: UserData.CompanyName,
                SenderCompanyType: UserData.CustomerType,
                SenderCompanyId: UserData.CompanyId,
                PrivateMessage: "",
                PrivateMessageList: [],
                MessageRead: [UserData.key],
            }

            if (EventData && TotalLength > 0) {
                NewEventData = [...EventData, NewEventAdd];
            } else {
                NewEventData = EventData;
            }

            SREvent.EventData = NewEventData;

            let CurrentProgress = {
                Progress: response,
                date: currentTime,
            }

            if (SREvent.ProgressHistory && SREvent.ProgressHistory.length > 0) {
                SREvent.ProgressHistory = [...SREvent.ProgressHistory, CurrentProgress];
            } else {
                SREvent.ProgressHistory = [CurrentProgress];
            }

            UpdateDatabase(SREvent);

            console.log("FeedBackModule.SREvent", SREvent);

            if (props.UpdateEvent) {
                props.UpdateEvent(SREvent);
            }
        } else {
            setState(prevState => ({
                ...prevState,
                AlarmMessage: "Please enter notes",
                OpenAlert: true
            }));
        }
        
    };

    
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setState(prevState => ({
            ...prevState,
            AlarmMessage: "",
            OpenAlert: false
        }));
        //Snackbar, Alert
    };

    console.log("FeedBackModule.props", props)
    console.log("FeedBackModule.state", state)

    return (
        <>

            <Card key={"crkkjhd1"}>
                <CardHeader
                    title="Confidential Feedback"
                    avatar={
                        <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe" >
                            {FindCategoryIcon("Feedback")}
                        </Avatar>
                    }
                />

                <CardContent>

                    <Grid container spacing={1}>

                        {state.Result !== "" ? (
                            <Grid xs={12} item>
                                <Card>
                                    <CardHeader
                                        avatar={state.avatar !== "" ? (
                                            <Avatar src={state.avatar} />
                                        ) : (
                                            <Avatar ></Avatar>
                                        )}

                                        subheader={`${state.field1} - ${state.FeedBackTime}`}
                                        title={state.Result}
                                    />
                                </Card>
                        </Grid>
                        ) : null}

                        <Grid xs={12} item>

                            <TextField
                                label="Notes"
                                name="Notes"
                                value={state.Notes || ""}
                                fullWidth
                                multiline
                                rows={2}
                                variant="outlined"
                                onChange={(e) => KeyArray(e.target.value, "Notes")}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                
                                <Grid xs={6} item>
                                    <Typography>
                                        Communication
                                    </Typography>
                                </Grid>
                                
                                <Grid xs={6} item>
                                    <HoverRating
                                        RatingsValue={state.Communication || ""}
                                        Ratings={e => KeyArray(e, "Communication")}
                                        key="Communication"
                                        readOnly={state.readOnly}
                                    />
                                    </Grid>
                              
                                
                                <Grid xs={6} item>
                                    <Typography>
                                        WorkmanShip
                                    </Typography>
                                    </Grid>
                                
                                <Grid xs={6} item>
                                    <HoverRating
                                        RatingsValue={state.WorkmanShip || ""}
                                        Ratings={e => KeyArray(e, "WorkmanShip")}
                                        key="WorkmanShip"
                                        readOnly={state.readOnly}
                                    />
                                    </Grid>
                                
                                <Grid xs={6} item>
                                    <Typography>
                                        Recommend our Service to Others
                                    </Typography>
                                </Grid>
                                <Grid xs={6} item>
                                    <HoverRating
                                        RatingsValue={state.Recommend || ""}
                                        Ratings={e => KeyArray(e, "Recommend")}
                                        key="Recommend"
                                        readOnly={state.readOnly}
                                    />
                                        </Grid>
                                    
                            </Grid>
                        </Grid>


                        <Grid xs={12} item>
                            <Grid container spacing={1}>
                                <Grid xs={6} item>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        disabled={state.readOnly}
                                        onClick={() => ProcessMessage("Accepted")}
                                    >
                                        Accept
                                    </Button>
                                </Grid>
                                <Grid xs={6} item>
                                    <Button
                                        color="error"
                                        variant="contained"
                                        disabled={state.readOnly}
                                        onClick={() => ProcessMessage("Rejected")}
                                    >
                                        Reject
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>


                    </Grid>
                </CardContent>
            </Card>

            <Snackbar
                open={state.OpenAlert}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                message={state.AlarmMessage}
            />
        </>

    );
};

export default FeedBackModule;
