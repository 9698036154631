import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
//import clsx from 'clsx';
import { useSelector } from 'react-redux';
//import moment from 'moment';
import {
    FormGroup,
    FormControlLabel,
    Switch,
    Button,
    TextField,
    Typography,
    Grid, Backdrop,
    CircularProgress,
    CardHeader,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import SelectAssets from '../components/SelectAssets';
//import Quill from '../components/quill'
import {
    NewPageId,
    removeEmptyStringElements,
    getAutoCompletedata,
    UpdateData
} from '../utils/CommonGraphql';
import AutoComplateBox from '../components/AutoComplete';
import { API, graphqlOperation } from "aws-amplify";
import { createSvcbotDb } from '../graphql/mutations';
//import { useDispatch } from 'react-redux';
import Page from '../components/Page';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import CloudUpload from '@mui/icons-material/CloudUpload';
//import { currentTime } from '../utils/DateTime';
import UploadMain from '../Upload/UploadMain';
import { SendEmail } from '../utils/SendEmail';

const NewId = NewPageId("AM")

const useStyles = makeStyles((theme) => ({
    root: {
        // backgroundColor: theme.palette.background.dark,
        //minHeight: '100%',
        padding: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
    },
}));

var dateToday = new Date();
let currentTime = dateToday.toString()
    //.replace("Z", "");
console.log("AddMessage.currentTime", currentTime);


const Profile = ({ className, ...rest }) => {
    const classes = useStyles();
    //const [Requestor, SetRequestor] = useState(false);
    const ContractorData = useSelector(state => state.ContractorData);
    const HQData = useSelector(state => state.HQData);
    const UserData = useSelector(state => state.UserData);
    const [SelectCompany, SetSelectCompany] = useState(false);
    const [AddPersonal, SetAddPersonal] = useState(false);
    const [Upload, SetUpload] = useState(false);
    const [loading, SetLoading] = useState(false);
    //const cid = useSelector(state => state.cid);
    //const dispatch = useDispatch()

    const initialState = {
        Message: "",
        MessageNo: "",
        Personal: [],
        PrivateMessage: false,
        CurrentCompany: UserData.CompanyName,
        CompanyId: UserData.CompanyId,
        NewId: NewId,
        PhotoService: [],
        Documents: [],
        ImageStateSet: [],
    }

    const [state, setState] = useState(initialState);



    useEffect(() => {

        switch (rest.UpdateCategory) {
            case "Accepted":
                GetMessageList("PostMessages", rest.SREvent.PostId, "MessageSentList");
                break;
            case "Canceled":
                GetMessageList("PostMessages", rest.SREvent.PostId, "MessageSentList");
                break;
            case "Delegate":
                SetAddPersonal(true);
                break;
        }

    }, [rest.UpdateCategory])

    const GetMessageList = async (Category, CompanyId, ArrayName, Asset = "", SMS = "") => {
        SetLoading(true);

        const DataString = {
            Category: Category,
            FindArray: [
                {
                    ArrayName: ArrayName,
                    ArraySearchString: ":",
                    extraVar: "status",
                    extraValue: "Active"
                },
            ]
        };

        let NewData = await getAutoCompletedata(DataString, CompanyId, Asset, SMS);
        setState(prevState => ({ ...prevState, ...NewData }))
        SetLoading(false);
        // console.log("UrgencyDetails.NewData", NewData);

        console.log("SMSCompanySelect.NewData.SMSs - NewData", NewData);

    }

    const SendValuesBack = () => {

        let TotalMessage = {
            Message: state.Message,
            Person: state.Personal,
            PrivateMessage: rest.Trigger1
        }

        if (rest && rest.SelectedValues) {
            rest.SelectedValues(TotalMessage)
        }

    }

    const SetValues = (e, name) => {
        setState(prevState => ({
            ...prevState,
            [name]: e
        }));
    }

    //const PrivateMessageProcess = (e, name) => {

    //  if (name === "Personal" && e && e.length > 0 ) {

    //  }

    //  setState(prevState => ({
    //    ...prevState,
    //    [name]: e
    //  }));


    //}

    const GetCompanyName = (data) => {

        setState(prevState => ({
            ...prevState,
            CurrentCompany: data.field1,
            CompanyId: data.CompanyId,
        }));

        SetSelectCompany(false);

    }

    const SaveImage = (name, e) => {
        setState(prevState => ({
            ...prevState,
            [name]: e
        }));
    };

    const ProcessMessage = () => {

        let DocFlag = false;
        let NotificationList = [];
        let PrivateMessageList = [UserData.key];
        let PersonallList = state.Personal;
        let Message = state.Message;
        let SREvent = rest.SREvent;
        let posterIdEmail = "";
        let posterIdExist = "";
        let CompletedMessage = "";

        console.log("AddMessage.state.Personal", state.Personal)
        //create a list for email and Private message
        if (state.MessageSentList && state.MessageSentList.length > 0) {
            PersonallList = [...PersonallList, ...state.MessageSentList];
        }

        if (SREvent.posterId && SREvent.posterId.email && SREvent.posterId.email !== "") {
            posterIdEmail = SREvent.posterId.email;
            posterIdExist = SREvent.posterId;
        }

       
        //put keys only if Private message is true
        if (!state.PrivateMessage) {
            PrivateMessageList = [];
        }

        let ManagerApprovedBy = []
        if (SREvent.ManagerApprovedBy) {
            ManagerApprovedBy = SREvent.ManagerApprovedBy;
        }
        
        let EventData = SREvent.EventData;
        let NewEventData = [];
        let TotalLength = EventData.length;
        let NewEventAdd = {
            PhotoService: state.PhotoService,
            Documents: state.Documents,
            key: state.NewId,
            Body: Message,
            TimeStamp: currentTime,
            SenderName: UserData.field1,
            PosterId: UserData.key,
            avatar: UserData.avatar,
            Action: rest.UpdateCategory,
            SenderCompany: UserData.CompanyName,
            SenderCompanyType: UserData.CustomerType,
            SenderCompanyId: UserData.CompanyId,
            PrivateMessage: state.PrivateMessage,
            PrivateMessageList: PrivateMessageList,
            MessageRead: [UserData.key],
        }

        switch (rest.UpdateCategory) {
            case "Accepted":
                NewEventAdd.Body = `Accepted by ${UserData.field1} - ${Message}`;
                SREvent.ManagerApprovedBy = [...ManagerApprovedBy, NewEventAdd];
                SREvent.status = rest.UpdateCategory;
                EndEsc(rest.SREvent)
                break;
            case "Canceled":
                NewEventAdd.Body = `Canceled by ${UserData.field1} - ${Message}`;
                SREvent.ManagerApprovedBy = [...ManagerApprovedBy, NewEventAdd];
                SREvent.status = rest.UpdateCategory;
                EndEsc(rest.SREvent)
                break;
            case "Completed":                
                CompletedMessage = `Work Marked Completed by ${UserData.field1} - ${Message}`;
                NewEventAdd.Body = CompletedMessage;
                SREvent.status = rest.UpdateCategory;
                if (posterIdEmail !== "") {
                    PersonallList = [...PersonallList, posterIdExist];
                } 
                    SendSMS(CompletedMessage, SREvent);     
                break;
        }

        if (PersonallList.length > 0) {
            PersonallList.map(each => {
                PrivateMessageList.push(each.key)
                NotificationList.push(each.email)
            })
        }

        //eleminate duplicates
        NotificationList = [...new Set(NotificationList)];

        console.log("AddMessage.NotificationList", NotificationList)

        if (rest.UpdateNumber < TotalLength) {
            EventData.splice(rest.UpdateNumber + 1, 0, NewEventAdd);
            NewEventData = EventData;
        } else {
            NewEventData = [...EventData, NewEventAdd];
        }

        if (state.PhotoService.length > 0) {
            let PhotoAdd = [...SREvent.PhotoService, ...state.PhotoService];
            SREvent.PhotoService = PhotoAdd;
            DocFlag = true;
        }

        if (state.Documents.length > 0) {
            let DocumentsAdd = [...SREvent.Documents, ...state.Documents];
            SREvent.Documents = DocumentsAdd;
            DocFlag = true;
        }

        SREvent.EventData = NewEventData;

        if (rest.UpdateEvent) {
            rest.UpdateEvent(SREvent);
        }

        if (DocFlag) {
            UpdateDocuments(SREvent);
        }

        console.log("AddMessage.sending", NotificationList);

        if (NotificationList.length > 0) {
            GoToLambda(NotificationList, SREvent, NewEventAdd);
        } else {

            if (rest.CloseDrawer && !DocFlag) {
                rest.CloseDrawer();
            }

        }

        console.log("AddMessage.NewEventData", NewEventData)
    }

    const UpdateDocuments = (event) => {

        try {

            let posterId = {
                pk1: UserData.pk1,
                sk1: UserData.sk1,
                field1: UserData.filed1,
                field2: UserData.filed2,
                avatar: UserData.avatar,
            }

            const DocData = {
                pk1: `Post::${event.PostId}`,
                sk1: "Post::Doc::" + event.ServiceRequest.sk1,
                gsi1Pk2: event.posterId.CompanyId,
                gsi1Sk2: `Doc::Post::${event.ServiceRequest.sk1}`,
                gsi2Pk2: event.posterId.CompanyId,
                gsi2Sk2: `Doc::Post::${event.ServiceRequest.sk1}`
            };

            let NewFormJSON = {
                // posterId: event.posterId,
                post: event.postTEXT,
                title: event.title,
                field1: `Document Type: Post`,
                field2: event.title,
                PhotoService: event.PhotoService,
                Documents: event.Documents,
                date: event.ServiceRequest.sk1,
                PostId: event.PostId,
                currentTime: currentTime,
                Category: "Documents",
                posterId: posterId,
                ...DocData
            };

            let DBInput = {
                ...DocData,
                dataJSON: JSON.stringify(removeEmptyStringElements(NewFormJSON))
            }

            API.graphql(graphqlOperation(createSvcbotDb, { input: DBInput })).then(
                (results) => {
                    console.log("createBatch Data ", results);
                    if (rest.CloseDrawer) {
                        rest.CloseDrawer();
                    }
                }
            );


        } catch (err) {
            console.log("SelectStepBox.err", err);
        }
    }

    const EndEsc = (event) => {

        try {

            let currenttimeline = new Date(dateToday).getTime();
            let SRtimeline = new Date(event.currentTime).getTime();
            let ResponseTime = parseInt((currenttimeline - SRtimeline) / (1000 * 60 * 60));

            let posterId = {
                pk1: UserData.pk1,
                sk1: UserData.sk1,
                field1: UserData.filed1,
                field2: UserData.filed2,
                avatar: UserData.avatar,
            }

            const DocData = {
                //pk1: `Post::${event.PostId}`,
                //sk1: `Post::Response::${rest.UpdateCategory}::${ResponseTime}`,
                //gsi1Pk2: event.posterId.CompanyId,
                //gsi1Sk2: `Post::Response::${rest.UpdateCategory}::${ResponseTime}`,
                //gsi2Pk2: event.ContractorData.ContractorId,
                //gsi2Sk2: `Post::Response::${rest.UpdateCategory}::${ResponseTime}`

                pk1: `Post::Response::${event.posterId.CompanyId}`,
                sk1: event.ServiceRequest.sk1,
                gsi1Pk2: `Post::${event.PostId}`,
                gsi1Sk2: `Post::Response::${rest.UpdateCategory}::${currentTime}`,
                gsi2Pk2: `Post::Response::${event.ContractorData.ContractorId}`,
                gsi2Sk2: currentTime,
            };


            let NewFormJSON = {
                PostId: event.PostId,
                currentTime: currentTime,
                ServiceRequest: event.ServiceRequest,
                field1: event.title,
                ResponseTime: ResponseTime,
                Category: "Response",
                posterId: posterId,
                ...DocData
            };

            let ResponseTimeTrack = {
                ...DocData,
                dataJSON: JSON.stringify(removeEmptyStringElements(NewFormJSON))
            }

            //const ResponseTimeTrack = {
                
            //    dataJSON: JSON.stringify(removeEmptyStringElements(NewFormJSON))
            //    //  dataJSON: JSON.stringify(removeEmptyStringElements(dataJSON))
            //};

            // API.graphql(graphqlOperation(createSvcbotDb, { input: DBInput }));

            UpdateData(ResponseTimeTrack)
                .then((up) => {
                    // dispatch({ type: 'TRIGGER_UPDATE', payload: true });
                    console.log("ProgressStepper.UpdateDatabase", up)
                })
                .catch((err) => console.log("UpdateDatabase.failed", err));

            //API.graphql(graphqlOperation(createSvcbotDb, { input: DBInput })).then(
            //    (results) => {
            //        console.log("createBatch Data ", results);
            //        if (rest.CloseDrawer) {
            //            rest.CloseDrawer();
            //        }
            //    }
            //);


        } catch (err) {
            console.log("SelectStepBox.err", err);
        }
    }


    //const GoToLambda = (NotificationList, event, NewEventAdd) => {

    //    try {

    //        var emailstring = {

    //            title: event.title,
    //            avatar: NewEventAdd.avatar,
    //            BackgroundColor: "#eeeeee",
    //            ButtonColor: "#44872e",
    //            HeadingColor: "#44872e",
    //            ButtonTextColor: "#ffffff",
    //            ContractorName: NewEventAdd.SenderCompany,
    //            ActionButton: `https://bomrng.com/${event.PostId}`,
    //            ContractorLogo: event.ContractorData.dataJSON.avatar,
    //            ActionButtonTitle: "See Details Online",
    //            headerBanner: "https://images.pexels.com/photos/4050299/pexels-photo-4050299.jpeg?cs=srgb&dl=woman-sitting-on-the-floor-using-a-laptop-4050299.jpg&fm=jpg",
    //            PhotoService: NewEventAdd.PhotoService,
    //            Documents: NewEventAdd.Documents,
    //            SpecialInstructions: "",
    //            PreSelectList: [],
    //            UnsubscribeLink: "https://bomrng.com/UnsubscribeLink",
    //            // email: "ali.s.rehan@jci.com", //each person email
    //            // title: event.title,
    //            //  Message: NewEventAdd.Body,
    //            postHTML: NewEventAdd.Body,
    //            postTEXT: NewEventAdd.Body,
    //            PersonList: NotificationList,
    //            PostId: event.PostId,
    //            ContractorId: event.ContractorData.gsi2Pk2,
    //            CompanyId: event.PersonData.CompanyId,
    //            ReplyEmail: event.SMSFeedbackMessages.ReplyEmail

    //        };

    //        console.log("AddMessage.emailstring", emailstring)

    //        if (emailstring) {
    //            API.post("TriggerEmail", "/post", {
    //                body: { Records: emailstring }
    //            });
    //        }

    //    } catch (err) { }

    //}

    const GoToLambda = (PersonList, event, NewEventAdd) => {

        try {
            console.log("AddMessage.sending.email", PersonList)
            // let LocalDate = new Date(currentTime).toLocaleString();
          //  let LandingPage = "ServiceRequest";
            let ContractorSubDomain = `https://${HQData.SubDomain}`;
            //let title = `${state.SubscriptionName} - ${LocalDate}`;

            let title = event.title;
            let postHTML = NewEventAdd.Body;
            let postTEXT = NewEventAdd.Body;

            let ButtonLinkpk = btoa(event.ServiceRequest.pk1);
            let ButtonLinksk = btoa(event.ServiceRequest.sk1);            

            switch (rest.UpdateCategory) {
                case "Accepted":
                    title = `Accepted - ${title}`;
                    
                    //postHTML = `<h3>Accepted by ${UserData.filed1}</h3> ${postHTML}`;
                    //postTEXT = `Accepted by ${UserData.filed1} \n\n ${postTEXT}`;
                    break;
                case "Canceled":
                    title = `Canceled - ${title}`;
                    //postHTML = `<h3>Canceled by ${UserData.filed1}</h3> ${postHTML}`;
                    //postTEXT = `Canceled by ${UserData.filed1} \n\n ${postTEXT}`;
                    break;
                case "Completed":
                    title = `Work Completed - ${title}`;
                   // LandingPage = "Completed";
                    //postHTML = `<h3>Canceled by ${UserData.filed1}</h3> ${postHTML}`;
                    //postTEXT = `Canceled by ${UserData.filed1} \n\n ${postTEXT}`;
                    break;
            }

            let ActionButton = `https://${HQData.SubDomain}/ServiceRequest/${ButtonLinkpk}/${ButtonLinksk}`;

            let SendEvent = {

                PhotoService: NewEventAdd.PhotoService,
                Documents: NewEventAdd.Documents,
                // SpecialInstructions: state.SpecialInstructions,
                PreSelect: [],
                title: title,
                PersonList: PersonList,
                postHTML: postHTML,
                postTEXT: postTEXT,
                ReplyEmail: "no-reply@service-call-cloud.com",
                PostId: event.PostId,

                ContractorAddress: ContractorData.Location.description,
                ContractorPhone: ContractorData.phone,
                ContractorLogo: ContractorData.avatar,
                ContractorName: ContractorData.CompanyName,

                UnsubscribeLink: `${ContractorSubDomain}/Unsubscribe`,
                ActionButton: ActionButton,
                ActionButtonTitle: "Go Online",
                ActionButtonShow: true
            }

            console.log("SubscriptionAdd1.SendEmail", SendEvent)

            SendEmail(SendEvent);

            if (rest.CloseDrawer) {
                rest.CloseDrawer();
            }



        } catch (err) { }

    }

    const SendSMS = (Message, event) => {

        try {
            console.log("AddMessage.sending.sms", event.RequestorSMS)
            let ButtonLinkpk = btoa(event.ServiceRequest.pk1);
            let ButtonLinksk = btoa(event.ServiceRequest.sk1); 
            let ActionButton = `https://${HQData.SubDomain}/ServiceRequest/${ButtonLinkpk}/${ButtonLinksk}`;

            var SMSstring = {
                Body: `${Message}. Please click on following link ${ActionButton}`,
               //FromSMS: state.phone_number,
                From: "+15623936334",
                To: [event.RequestorSMS],
                mediaUrl: []
            };

            console.log(
                "AddMessage.Completed",
                SMSstring
            );

            API.post("SMStoTwilio", "/twilio", {
                body: { Records: SMSstring }
            });

        } catch (err) { }

    }

    //console.log("Requestor.ContractorData", ContractorData)
    //console.log("Requestor.UserData", UserData)
    //console.log("Requestor.cid", cid)
    //console.log("Requestor.state", state)
    console.log("AddMessage.props", rest)
    console.log("AddMessage.state", state)

    return (
        <Page className={classes.paper}>
            <Grid container spacing={1}>
                <Grid xs={12} item>

                    <Typography variant="h4">
                        {rest.DrawerTitle}
                    </Typography>
                </Grid>
                <Grid xs={12} item>

                    <TextField
                        label="Add Message"
                        name="Message"
                        value={state.Message || ""}
                        fullWidth
                        multiline
                        rows={2}
                        variant="outlined"
                        onChange={(e) => SetValues(e.target.value, "Message")}
                    />
                </Grid>

                <Grid xs={12} item>
                    <FormGroup>
                        <FormControlLabel control={<Switch
                            //disabled={state.Priority === "Emergency"}
                            checked={state.PrivateMessage || ""}
                            onChange={(e) => SetValues(e.target.checked, "PrivateMessage")}
                        />} label="Private Message" />
                    </FormGroup>
                </Grid>

                {state.PrivateMessage && (
                    <Grid item xs={12} >

                        <Typography>
                            The Message will show for only the personal selected below
                        </Typography>

                    </Grid>
                )}

                <Grid item xs={12}>
                    <Button
                        onClick={() => SetAddPersonal(!AddPersonal)}
                        //variant="contained"
                        //color="primary"
                        color="secondary"
                        startIcon={<AddCircleOutline />}
                    >
                        Add Personal/Email Notification
                    </Button>
                </Grid>

                {AddPersonal && (
                    <>
                        <Grid item xs={12} sm={6} >

                            <Typography variant="h4">
                                {state.CurrentCompany}
                            </Typography>

                            {UserData.CustomerType === "Contractor" && rest.Category !== "Company" && (
                                <Button
                                    //disabled={activeStep === 0}
                                    variant="contained"
                                    onClick={() => SetSelectCompany(!SelectCompany)}
                                    className={classes.button}
                                >
                                    Change Company
                                </Button>
                            )}

                        </Grid>

                        {SelectCompany && UserData.CustomerType === "Contractor" && (
                            <Grid
                                item
                                xs={12}
                            >
                                <AutoComplateBox
                                    //SaveDetails={rest.SaveDetails}
                                    //FormCompleteVerify={e => console.log("FormCompleteVerify", e)}
                                    Category="Company"
                                    FormType="Company"
                                    title="Company"
                                    // id={Newpageiddd}
                                    ShowEditButton={false}
                                    CompanyId={ContractorData.CompanyId}
                                    ReturnBackData={e => console.log(e)}
                                    PreviewEdit={false}
                                    EditItem={e => console.log("EditItem", e)}
                                    //FormData={each.FormData}
                                    onSubmit={(model) => GetCompanyName(model)}
                                />
                            </Grid>
                        )}

                        <Grid xs={12} item>
                            <SelectAssets
                                SelectedAsset={(e) => SetValues(e, "Personal")}
                                CompanyId={state.CompanyId}
                                CompanyName={state.CurrentCompany}
                                AssetList={state.Personal}
                                title="Select Personal"
                                Category="Person"
                            // FormData={rest.FormData}
                            />
                        </Grid>

                    </>
                )}

                <Grid item xs={12}>
                    <Button
                        onClick={() => SetUpload(!Upload)}
                        //variant="contained"
                        //color="primary"
                        color="secondary"
                        startIcon={<CloudUpload />}
                    >
                        Upload Document / Pictures
                    </Button>
                </Grid>

                {Upload && (

                    <UploadMain
                        id="PhotoService"
                        key="PhotoService"
                        title="Upload Images, PDF, or Zip"
                        ImageList={(e) => SaveImage("PhotoService", e)}
                        DocumentList={(e) => SaveImage("Documents", e)}
                        coid={ContractorData.ContractorId}
                        ImageStateSet={(e) => SaveImage("ImageStateSet", e)}
                        ImageStateSetBack={state.ImageStateSet}
                        fileallowed="any"
                        cat="PhotoNamePlate"
                    />

                )}

                <Grid xs={12} item>
                    <Button color="primary" variant="contained" onClick={() => ProcessMessage()}>
                        Update Messages
                    </Button>
                </Grid>

            </Grid>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            // onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Page>
    );
};

Profile.propTypes = {
    className: PropTypes.string
};

export default Profile;
