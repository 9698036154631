import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import LoginView from '../views/auth/LoginView';
import CompanyDetails from '../Setup/CompanyDetails';
import NotFoundView from '../views/errors/NotFoundView';
import ProductListView from '../views/product/ProductListView';
import Tabs from '../components/Tabs';
import Accordion from '../components/Accordion';
import ListModule from '../List/ListModule';
import Shift from '../Setup/RecurringSchedule';
import ListCheckbox from '../List/ListCheckbox';
import ListRadio from '../List/ListRadio';
import ListCheckboxMoveUpDown from '../List/ListCheckboxMoveUpDown';
import ListItems from '../List/ListItems';
import NewServiceSetup from '../Setup/NewServiceSetup';
import AddSetupSelection from '../Setup/AddSetupSelection';
import ComboForm from '../Forms/ComboForm';
import FormCreatorClass from '../Forms/FormCreatorClass';
import ItemAddPage from '../Add/ItemAddPage';
import NewServicePage from '../NewService/NewServicepage';
import NewServiceSteps from '../NewService/NewServiceSteps';
import Details from '../Add/ItemShowDetails';
import ShowDetails from '../Add/ShowDetails';
import ShowDetailsPkSk from '../Add/ShowDetailsPkSk';
import ESCSetupPage from '../Setup/ESCMinSetup';
import ESCPerStep from '../Setup/ESCPerStep';
import ESCSelectCompany from '../Setup/ESCSelectCompany';
import ESCSelectContractor from '../Setup/ESCSelectContractor';
//import ESCPersonal from '../Setup/ESCPersonal';
import ESCAddPersonal from '../Setup/ESCAddPersonal';
import CompanyPrefSetup from '../Setup/CompanyPrefSetup'
import SelectPreferance from '../Setup/SelectPreferance'
import PersonalCheckList from '../components/PersonalCheckList';
import Title from '../components/Title';
import FormPreview from '../Forms/AnyForm';
import FormSetup from '../Setup/FormsSetup';
import ExtraNotification from '../NewService/ExtraNotification';
//import Quill from '../components/quill';
import AutocompleteModule from '../components/AutoComplete';
import UploadMain from '../Upload/CombineUploadAndShow';
import ShowImage from '../Upload/ShowImages';
import DateTimeRange from '../components/DateTimeRange';
import ToolbarHeading from '../components/ToolbarHeading';
import DateRange from '../components/DateRange';
import TimeRange from '../components/TimeRange';
import ShowTradeOption from '../NewService/ShowTradeOption';
import SelectStepBox from '../Setup/SelectStepBox';
import SMSQuestions from '../Setup/SMSQuestions';
import SMSListQuestions from '../Setup/SMSListQuestions';
import ServiceActionList from '../Setup/ServiceActionList';
import UrgencyDetails from '../Setup/UrgencyDetails';
import ListSetupOptions from '../Setup/ListSetupOptions';
import NotificationTriggerSetup from '../Setup/NotificationTriggerSetup';
import ShowServiceSteps from '../NewService/ShowServiceSteps';
import SRDetails from '../List/SRDetails';
import ProgressList from '../Setup/ProgressList';
import ProgressBox from '../Setup/ProgressBox';
import ProgressSelectSteps from '../Setup/ProgressSelectSteps';
import ProgressStepper from '../Setup/ProgressStepper';
import SetupPage from '../Setup/Setup';
import SMSSetup from '../Setup/SMSSetup';
import EmailTriggerSetup from '../Setup/EmailTriggerSetup';
import SMSAddEdit from '../Setup/SMSAddEdit';
import SRPage from '../List/SRPage';
import Calendar from '../Calendar/Calendar';
import CalendarBox from '../Calendar/CalendarBox';
import PersonCalendarBox from '../Calendar/PersonCalendarBox';
import ESCTeamEdit from '../Setup/ESCTeamEdit';
import { NewPageId } from '../utils/CommonGraphql';
import CreateContactUs from '../components/CreateContactUs';
import ContactList from '../components/ContactList';
import ColorPicker from '../components/ColorPicker';
import PrioritySelection from '../Setup/PrioritySelection';
import ManagerApproval from '../Setup/ManagerApproval';
import ColorSelection from '../Setup/ColorSelection';
import PreSelect from '../components/PreSelect';
import SpecialInstructions from '../Setup/SpecialInstructions';
import SMSMessagesBack from '../Setup/SMSMessagesBack';
import ESCContractorDefault from '../Setup/ESCContractorDefault';
import AddMessage from '../components/AddMessage';
import ManagerApproved from '../components/ManagerApproved';
import AcceptDecline from '../components/AcceptDecline';
import CreateEmailTrigger from '../Setup/CreateEmailTrigger';
import SilenceNotification from '../Setup/SilenceNotification';
import Chart1 from '../Charts/Chart1';
import ShowPreSelect from '../components/ShowPreSelect';
import ShowInfoHistory from '../Add/ShowInfoHistory';
import DocumentPage from '../List/DocumentPage';
import SubscriptionSend from '../Setup/SubscriptionSend';
import SubscriptionAdd from '../Setup/SubscriptionAdd';
import ShowDetailsText from '../Add/ShowDetailsText';
import AddAsset from '../Setup/AddAsset';
import PreSelectEmail from '../components/PreSelectEmail';
import TimeZonePreSelect from '../Setup/TimeZonePreSelect';
import BrandingUpdate from '../Add/BrandingUpdate';
import AddDocuments from '../Setup/AddDocuments';
import SRDetailsPkSk from '../List/SRDetailsPkSk';
import MapPage from '../List/MapPage';
import SMSCompnaySelect from '../Setup/SMSCompnaySelect';
import BranchCharts from '../components/BranchCharts';
import SRShowSingle from '../List/SRShowSingle'; //
import RatingChart from '../Charts/RatingChart';

//ServiceRequest

const RenderModule = (props) => {
    const SelectCategory = () => {



        const { LoadComponenet, ...rest } = props

        let NewProps = { ...rest };
        //console.log("RenderModule.props.NewProps", props);

        switch (props.LoadComponenet) {

            case "RatingChart":
                return <RatingChart {...NewProps} />;

            case "SRShowSingle":
                return <SRShowSingle {...NewProps} />;

            case "BranchCharts":
                return <BranchCharts {...NewProps} />;

            case "SMSCompnaySelect":
                return <SMSCompnaySelect {...NewProps} />;

            case "MapPage":
                return <MapPage {...NewProps} />;

            case "SRDetailsPkSk":
                return <SRDetailsPkSk {...NewProps} />;

            case "AddDocuments":
                return <AddDocuments {...NewProps} />;

            case "BrandingUpdate":
                return <BrandingUpdate {...NewProps} />;

            case "TimeZonePreSelect":
                return <TimeZonePreSelect {...NewProps} />;

            case "PreSelectEmail":
                return <PreSelectEmail {...NewProps} />;

            case "AddAsset":
                return <AddAsset {...NewProps} />;

            case "ShowDetailsText":
                return <ShowDetailsText {...NewProps} />;

            case "SubscriptionAdd":
                return <SubscriptionAdd {...NewProps} />;

            case "SubscriptionSend":
                return <SubscriptionSend {...NewProps} />;

            case "DocumentPage":
                return <DocumentPage {...NewProps} />;

            case "ShowInfoHistory":
                return <ShowInfoHistory {...NewProps} />;

            case "ShowPreSelect":
                return <ShowPreSelect {...NewProps} />;

            case "PersonCalendarBox":
                return <PersonCalendarBox {...NewProps} />;

            case "ESCPerStep":
                return <ESCPerStep {...NewProps} />;

            case "EmailTriggerSetup":
                return <EmailTriggerSetup {...NewProps} />;

            case "ToolbarHeading":
                return <ToolbarHeading {...NewProps} />;

            case "Chart1":
                return <Chart1 {...NewProps} />;

            case "SilenceNotification":
                return <SilenceNotification {...NewProps} />;

            case "CreateEmailTrigger":
                return <CreateEmailTrigger {...NewProps} />;

            case "AcceptDecline":
                return <AcceptDecline {...NewProps} />;

            case "ManagerApproved":
                return <ManagerApproved {...NewProps} />;

            case "ProgressStepper":
                return <ProgressStepper {...NewProps} />;

            case "AddMessage":
                return <AddMessage {...NewProps} />;

            case "ESCContractorDefault":
                return <ESCContractorDefault {...NewProps} />;

            case "ShowDetailsPkSk":
                return <ShowDetailsPkSk {...NewProps} />;

            case "SMSMessagesBack":
                return <SMSMessagesBack {...NewProps} />;

            case "SpecialInstructions":
                return <SpecialInstructions {...NewProps} />;

            case "PreSelect":
                return <PreSelect {...NewProps} />;

            case "ColorSelection":
                return <ColorSelection {...NewProps} />;

            case "ShowDetails":
                return <ShowDetails {...NewProps} />;

            case "ManagerApproval":
                return <ManagerApproval {...NewProps} />;

            case "PrioritySelection":
                return <PrioritySelection {...NewProps} />;

            case "ColorPicker":
                return <ColorPicker {...NewProps} />;

            case "ContactList":
                return <ContactList {...NewProps} />;

            case "CreateContactUs":
                return <CreateContactUs {...NewProps} />;

            case "ESCTeamEdit":
                return <ESCTeamEdit {...NewProps} />;

            case "CalendarBox":
                return <CalendarBox {...NewProps} />;

            case "Calendar":
                return <Calendar {...NewProps} />;

            case "ShowServiceSteps":
                return <ShowServiceSteps {...NewProps} />;

            case "SMSAddEdit":
                return <SMSAddEdit {...NewProps} />;

            case "SMSSetup":
                return <SMSSetup {...NewProps} />;

            case "SRPage":
                return <SRPage {...NewProps} />;

            case "SetupPage":
                return <SetupPage {...NewProps} />;

            case "SRDetails":
                return <SRDetails {...NewProps} />;

            case "ProgressList":
                return <ProgressList {...NewProps} />;

            case "ProgressBox":
                return <ProgressBox {...NewProps} />;

            case "ProgressSelectSteps":
                return <ProgressSelectSteps {...NewProps} />;

            case "ESCSelectCompany":
                return <ESCSelectCompany {...NewProps} />;

            case "ESCSelectContractor":
                return <ESCSelectContractor {...NewProps} />;

            case "SMSQuestions":
                return <SMSQuestions {...NewProps} />;

            case "SMSListQuestions":
                return <SMSListQuestions {...NewProps} />;

            case "ESCAddPersonal":
                return <ESCAddPersonal {...NewProps} />;

            case "Tabs":
                return <Tabs {...NewProps} />;

            case "NewServicePage":
                return <NewServicePage {...NewProps} />;

            case "NewServiceSteps":
                return <NewServiceSteps {...NewProps} />;

            case "Accordion":
                return <Accordion {...NewProps} />;

            case "Details":
                return <Details {...NewProps} />;

            case "ComboForm":
                return <ComboForm {...NewProps} />;

            case "ItemAddPage":
                return (<ItemAddPage {...NewProps} />)

            case "ListModule":
                return (<ListModule {...NewProps} />)

            case "ListItems":
                return (<ListItems {...NewProps} />)

            case "ListCheckbox":
                return (<ListCheckbox {...NewProps} />)

            case "ListRadio":
                return (<ListRadio {...NewProps} />)

            case "NewServiceSetup":
                return (<NewServiceSetup {...NewProps} />)

            case "CompanyDetails":
                return (<CompanyDetails {...NewProps} />)

            case "FormCreatorClass":
                return (<FormCreatorClass {...NewProps} />)

            case "ESCSetupPage":
                return (<ESCSetupPage {...NewProps} />)

            case "AddSetupSelection":
                return (<AddSetupSelection {...NewProps} />)

            case "SelectPreferance":
                return (<SelectPreferance {...NewProps} />)

            //case "ESCPersonal":
            //  return (<ESCPersonal {...NewProps} />)

            case "CompanyPrefSetup":
                return (<CompanyPrefSetup {...NewProps} />)

            case "PersonalCheckList":
                return (<PersonalCheckList {...NewProps} />)

            case "FormPreview":
                return (<FormPreview {...NewProps} />)

            case "FormSetup":
                return (<FormSetup {...NewProps} />)

            case "Shift":
                return (<Shift {...NewProps} />)

            case "ExtraNotification":
                return (<ExtraNotification {...NewProps} />)

            case "PrivateMessage":
                return (<ExtraNotification {...NewProps} />)

            case "Title":
                return (<Title {...NewProps} />)


            case "Autocomplete":
                return (<AutocompleteModule {...NewProps} />)

            case "Upload":
                return (<UploadMain {...NewProps} />)

            case "ShowImage":
                return (<ShowImage {...NewProps} />)

            case "DateTimeRange":
                return (<DateTimeRange {...NewProps} />)

            case "DateRange":
                return (<DateRange {...NewProps} />)

            case "TimeRange":
                return (<TimeRange {...NewProps} />)

            case "ShowTradeOption":
                return (<ShowTradeOption {...NewProps} />)

            case "SelectStepBox":
                return (<SelectStepBox {...NewProps} />)

            case "ServiceActionList":
                return (<ServiceActionList {...NewProps} />)

            case "ListCheckboxMoveUpDown":
                return (<ListCheckboxMoveUpDown {...NewProps} />)

            case "UrgencyDetails":
                return (<UrgencyDetails {...NewProps} />)

            case "ListSetupOptions":
                return (<ListSetupOptions {...NewProps} />)

            case "NotificationTriggerSetup":
                return (<NotificationTriggerSetup {...NewProps} />)

            default:
                return <ProductListView {...NewProps} />;
        }
        //  })
        //}

    };

    return SelectCategory();
};

RenderModule.propTypes = {
    LoadComponenet: PropTypes.string
};

export default RenderModule;
