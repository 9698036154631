import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { useParams, useLocation, Link } from 'react-router-dom';
//import { useLocation } from "react-router-dom"
import clsx from 'clsx';
import {
    Box,
    Button,
    Typography,
    Grid,
    Container,
    Card,
    CardContent,
    TextField,
    InputAdornment,
    SvgIcon,
} from '@mui/material';
//import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'react-redux';
//import { Search as SearchIcon } from 'react-feather';
//import AutoComplateBox from '../components/AutoComplete';
import { NewPageId } from '../utils/CommonGraphql';
//import Tabs from '../components/Tabs';
import { useDispatch } from 'react-redux';
import {
    CompanyFrontPage,
    ContractorFrontPage, HQFrontPage,
    ServiceLanding
} from '../Company/TabsList';
import Page from '../components/Page';
import RenderModule from '../utils/RenderModule';
//import ToolbarHeading from '../components/ToolbarHeading';

//


//const useStyles = makeStyles((theme) => ({
//  root: {
//    margin: theme.spacing(1)
//  },
//  importButton: {
//    marginRight: theme.spacing(1)
//  },
//  exportButton: {
//    marginRight: theme.spacing(1)
//  }
//}));

//let TempLoadComponent = [
//    {
//        LoadComponenet: "ListItems",
//        title: "title 1",
//        size: 6,
//    },
//    {
//        LoadComponenet: "ListItems",
//        title: "title 2",
//        size: 6,
//    },
    
//    {
//        LoadComponenet: "ListItems",
//        title: "title 2",
//        size: 6,
//    },
//]



const FrontPage = (props) => {
    // const classes = useStyles();
    const dispatch = useDispatch();
    const params = useParams();
    const [SelectCompany, SetSelectCompany] = useState(false);
    const ContractorData = useSelector(state => state.ContractorData);
    const UserData = useSelector(state => state.UserData);
    const ServiceLandingPage = useSelector(state => state.ServiceLandingPage);
    console.log('FrontPage.ServiceLandingPage', ServiceLandingPage);
    console.log('FrontPage.props', props);
    const items = useLocation();

    // const match = useRouteMatch();

    const initialState = {
        PopulatePage: [],
        Newpageiddd: ""
    };

    const [state, setState] = useState(initialState);

    useEffect(() => {
        NewValues(ServiceLandingPage)
    }, [UserData])

    useEffect(() => {
        NewValues(ServiceLandingPage)
    }, [props.NewPgId])

    //useEffect(() => {
    //    NewValues()
    //}, [items, params])

    const NewValues = (ShowServiceLandingPage) => {

        let Newpageiddd = NewPageId("page");

        dispatch({ type: 'NOW_EDITING', payload: UserData.CustomerType });

        let propss = {
            CompanyId: UserData.CompanyId,
            CompanyName: UserData.CompanyName,
            UserEditor: UserData.CustomerType,
            NowEditing: UserData.CustomerType,
            CustomerType: UserData.CustomerType,
           // ActiveCategory: props.ActiveCategory,
            Activefield1: UserData.field1,
            ActiveKey: UserData.sk1,
            CompanyName: UserData.CompanyName,
        };

        let PopulatePage = CompanyFrontPage(propss);
        
        if (ShowServiceLandingPage === true) {

            PopulatePage = ServiceLanding(propss);

        } else {

            switch (UserData.CustomerType) {
                case "Contractor":
                    if (UserData.Level > 800) {
                        PopulatePage = HQFrontPage(propss);
                    } else {
                        PopulatePage = ContractorFrontPage(propss);
                    }
                    break;
                case "Company":
                    PopulatePage = CompanyFrontPage(propss);
                    break;
                default:
                    PopulatePage = CompanyFrontPage(propss);
            }
        }

       // PopulatePage = ContractorFrontPage(propss);

        console.log('FrontPage.PopulatePage', PopulatePage);

        setState(prevState => ({
            ...prevState,
            PopulatePage: PopulatePage,
            Newpageiddd: Newpageiddd
        }));

    }

    const CommonState = (item) => {

        setState(prevState => ({
            ...prevState,
            [item.itemname]: item.itemvalue
        }));

    }

    console.log('FrontPage.state', state);
    console.log('FrontPage.props', props);
    //console.log('FrontPage.items.state.item', items.state.item);

    return (

        <Page
            title={UserData.ContractorName}
            key={state.Newpageiddd + "page"}
        >
            <Grid container spacing={1} >
                <Grid item xs={12}>
                    <Typography variant="h2">
                        {UserData.CompanyName}
                    </Typography>
                </Grid>

                {state.PopulatePage.map((each, indd) => (
                    <Grid item xs={12} lg={each.size} key={state.Newpageiddd + "grd" + indd} >
                        {each.Category && (<RenderModule
                            key={state.Newpageiddd + "page" + indd}
                            id={state.Newpageiddd + "idd" + indd}
                            SearchWords={state.SearchWords}
                            ShiftShow={state.ShiftShow}
                            PutinCommonState={(e) => CommonState(e)}
                            Category={each.Category}
                            {...each}
                        />)}
                    </Grid>
                ))}
            </Grid>
        </Page>

    );
};


export default FrontPage;
