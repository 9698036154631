import React, { useState, useEffect } from 'react';
import {
    Card, CardHeader, CardContent,
    Box, Button,
    Backdrop, CircularProgress, Grid, TextField, Snackbar, Typography
} from "@mui/material";
import { API, graphqlOperation } from "aws-amplify";
import { createSvcbotDb } from '../graphql/mutations';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import DrawerShow from '../utils/DrawerModule';
import {
    NewPageId,
    getAutoCompletedata,
    removeEmptyStringElements,
    UpdateData
} from '../utils/CommonGraphql';
import ListCheckboxMoveUpDown from '../List/ListCheckboxMoveUpDown';
//import ListModule from '../List/ListModule';
//import { currentTime } from '../utils/DateTime';

var dateToday = new Date();
let currentTime = dateToday.toISOString().replace("Z", "");

const UUid = NewPageId("SMSCompanySelect");

const ListItems = (props) => {
    const dispatch = useDispatch();
    //let query = useQuery();
    const params = useParams();

    //get Redux
    const UserData = useSelector(state => state.UserData)
    const ContractorData = useSelector(state => state.ContractorData);
    const CurrentCompany = useSelector(state => state.CurrentCompany);
    const NowEditing = useSelector(state => state.NowEditing)
    //if window is mobile or regular
    // const matches = useMediaQuery('(min-width:600px)');
    const [DrawerStatus, OpenDrawer] = useState(false);
    const [loading, SetLoading] = useState(false);
    const initialState = {
        Blocks: [],
        SearchWords: "",
        ShowDetails: false,
        anchor: "left",
        BoxWidth: 6,
        AllowAdd: true,
        ListType: "ListModule",
        CompanyId: UserData.CompanyId,
        ContractorData: [],
        CompanyData: [],
        propss: {},
        key: NewPageId("win"),
        SMSJson: [],
        Category: "AddSMS",
        Activefield1: props.CompanyName,
        defaultValue: 0,
        DeActivePolicies: [],
        CompanyPref: [],
        SelectedArray: [],
        status: "Active",
        IntroVideo: "",
        IntroVideoList: [],
        VideoName: "",
        OpenAlert: false,
        AlarmMessage: "",
        //NewLoadComponent: "UrgencyDetails"
    }
    const [state, setState] = useState(initialState);

    useEffect(() => {

        let SelectedSMS = [];
        let SelectedVideo = [];
        let IntroVideoList = [];
        
        console.log('SMSSetup.props', props);
        //get list of all active SMS numbers in use for the branch
        GetContractorSMS("AddSMS", ContractorData.ContractorId, "Data");

        if (NowEditing === "Contractor") {
            if (ContractorData
                && ContractorData.IntroVideoList
                && ContractorData.IntroVideoList.length > 0
            ) {
                IntroVideoList = ContractorData.IntroVideoList;
                SelectedVideo = ContractorData.SelectedVideo;
            }

            if (ContractorData
                && ContractorData.SelectedSMS
                && ContractorData.SelectedSMS.length > 0
            ) {
                SelectedSMS = ContractorData.SelectedSMS;
            }
        }

        console.log('SMSSetup1.SelectedVideo', NowEditing, SelectedVideo, IntroVideoList);
        console.log('SMSSetup1.ContractorData.IntroVideoList', ContractorData.IntroVideoList);

        if (NowEditing === "Company") {
            if (CurrentCompany
                && CurrentCompany.SelectedSMS
                && CurrentCompany.SelectedSMS.length > 0
            ) {
                SelectedSMS = CurrentCompany.SelectedSMS;
            }

            if (CurrentCompany
                && CurrentCompany.IntroVideoList
                && CurrentCompany.IntroVideoList.length > 0
            ) {
                IntroVideoList = ContractorData.IntroVideoList;
                SelectedVideo = CurrentCompany.SelectedVideo;
            }
        }

        

        setState(prevState => ({
            ...prevState,
            SelectedSMS: SelectedSMS,
            IntroVideoList: IntroVideoList,
            SelectedVideo: SelectedVideo
        }));

    }, [NowEditing])


    // getting list of SMS's
    const GetContractorSMS = async (Category, CompanyId, ArrayName, Asset = "", SMS = "") => {
        SetLoading(true);

        const DataString = {
            Category: Category,
            FindArray: [
                {
                    ArrayName: ArrayName,
                    ArraySearchString: ":",
                    extraVar: "status",
                    extraValue: "Active"
                },
            ]
        };

        let NewData = await getAutoCompletedata(DataString, CompanyId, Asset, SMS);
        setState(prevState => ({ ...prevState, ...NewData }))
        SetLoading(false);
        // console.log("UrgencyDetails.NewData", NewData);

        console.log("SMSCompanySelect.NewData.SMSs - NewData", NewData);

    }

    const CloseDrawer = () => {
        GetContractorSMS("AddSMS", ContractorData.ContractorId, "Data");
        //get deactive policies
       // GetDatafromDB("SMSCompany", props.CompanyId, "DeActivePolicies", "status", "Disabled");
        OpenDrawer(false);
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setState(prevState => ({
            ...prevState,
            AlarmMessage: "",
            OpenAlert: false
        }));
        //Snackbar, Alert
    };


    const SaveData = () => {

        try {

            let EditCommpny = CurrentCompany;

            if (NowEditing === "Contractor") {
                EditCommpny = ContractorData
            }

            let posterId = {
                pk1: UserData.pk1,
                sk1: UserData.sk1,
                field1: UserData.field1,
                field2: UserData.field2,
                avatar: UserData.avatar,
            }

            const FormdataJSON = {
                ...EditCommpny,
                SelectedSMS: state.SelectedSMS,
                SelectedSMSArray: state.SelectedSMSArray,
                IntroVideoList: state.IntroVideoList,
                SelectedVideo: state.SelectedVideo,
                posterId: posterId,
            }

            let DBInput2 = {
                pk1: EditCommpny.pk1,
                sk1: EditCommpny.sk1,
                dataJSON: JSON.stringify(removeEmptyStringElements(FormdataJSON))
            }

            console.log("SelectStepBox.DBInput", DBInput2);
            console.log("SelectStepBox.FormdataJSON", FormdataJSON);

            if (NowEditing === "Contractor") {
                dispatch({ type: 'CONTRACTOR_DATA', payload: FormdataJSON });
            } else {
                dispatch({ type: 'CURRENT_COMPANY', payload: FormdataJSON });
            }

            UpdateData(DBInput2)
                .then((up) => {
                    console.log("SRDetails.Update.results", up)

                    if (props.CloseDrawer) {
                        props.CloseDrawer();
                    }
                    // dispatch({ type: 'TRIGGER_UPDATE', payload: true });
                })
                .catch((err) => console.log("SRDetails.failed", err));

        } catch (err) {
            console.log("SelectStepBox.err", err);
        }
    }

    const AddItem = (data) => {
        let NewKey = NewPageId("smsset");
        setState(prevState => ({
            ...prevState,
            //DetailComponent: "NotificationTriggerSetup",
            DetailComponent: "ItemAddPage",
            ShowDetails: false,
            DetailsProps: {
                //title: add.field1,
                Category: "AddSMS",
                CompanyId: props.CompanyId,
                CompanyName: props.CompanyName,
                CloseDrawer: (e) => CloseDrawer(e),
                ActiveCategory: props.ActiveCategory,
                Activefield1: props.Activefield1,
                ActiveKey: props.ActiveKey,
                //ShowEditButton: false,
                //PreviewEdit: false,
                Data: {
                    mobile: "", //data.mobile,
                    //field1: data.field1,
                    //field2: data.field2,
                    //id: NewKey,
                    Category: state.Category,
                    CompanyId: props.CompanyId,
                    CompanyName: props.CompanyName,
                }
            },
            // key: add.key || NewKey
        }))

        OpenDrawer(true);
    }

    const KeyArray = (SelectedSMS) => {

        console.log("SMSCompanySelect.SelectedSMS", SelectedSMS);
        let findingData = {};
        let SelectedSMSArray = [];
        let ContactCardFileFullPath = "";

        if (SelectedSMS && SelectedSMS.length > 0) {
            SelectedSMS.map((each, indd) => {
                findingData = state.Data.find(x => x.key === each);

                if (findingData) {

                    if (findingData.ContactCardFileFullPath) {
                        ContactCardFileFullPath = findingData.ContactCardFileFullPath;
                    } else {
                        ContactCardFileFullPath = "";
                    }

                    SelectedSMSArray.push({
                        pk1: findingData.pk1,
                        sk1: findingData.sk1,
                        field1: findingData.field1,
                        field2: findingData.field2,
                        caption: findingData.field1,
                        label: findingData.field1,
                        filetypes: 10,
                        ImageFullPath: ContactCardFileFullPath
                    })
                }

            })
        }

        setState(prevState => ({
            ...prevState,
            SelectedSMS: SelectedSMS,
            SelectedSMSArray: SelectedSMSArray
        }));

        //  NewValues(data)
    }

    const AddVideoArray = (SelectedVideo) => {

        console.log("SMSCompanySelect.SelectedVideo", SelectedVideo);
        let findingData = {};
        let SelectedVideoArray = [];
        let ContactCardFileFullPath = "";

        //if (SelectedSMS && SelectedSMS.length > 0) {
        //    SelectedSMS.map((each, indd) => {
        //        findingData = state.IntroVideoList.find(x => x.key === each);

        //        if (findingData) {

        //            //if (findingData.ContactCardFileFullPath) {
        //            //    ContactCardFileFullPath = findingData.ContactCardFileFullPath;
        //            //} else {
        //            //    ContactCardFileFullPath = "";
        //            //}

        //            SelectedVideoArray.push(findingData)
        //        }
        //    })
        //}

        setState(prevState => ({
            ...prevState,
            SelectedVideo: SelectedVideo,
            // SelectedVideoArray: SelectedVideoArray
        }));

        //  NewValues(data)
    }

    const SetValues = (e, name) => {

        setState(prevState => ({
            ...prevState,
            [name]: e,
        }));

    }

    const AddVideo = () => {
        let IntroVideoList = state.IntroVideoList;

        let existing = IntroVideoList.find(x => x.key === state.IntroVideo);

        if (state.VideoName !== "" && state.IntroVideo !== "" && existing === undefined) {
            IntroVideoList.push({
                field1: state.VideoName,
                field2: state.IntroVideo,
                ImageFullPath: state.IntroVideo,
                caption: state.VideoName,
                key: state.IntroVideo,
                sk1: state.IntroVideo,
                filetypes: 3,
                MediaContentType: "video/mp4"
            })

            setState(prevState => ({
                ...prevState,
                IntroVideoList: IntroVideoList
            }));

        } else {

            setState(prevState => ({
                ...prevState,
                AlarmMessage: "Please enter correct information",
                OpenAlert: true
            }));

        }
    }

    console.log("SMSCompanySelect.state", state);

    return (
        <>
            <Grid container spacing={2}>

                <Grid item xs={12}>
                    <Box
                        display="flex"
                        justifyContent="flex-end"
                    // sx={{ m: 1 }}
                    >
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => AddItem()}
                        >
                            Add New SMS
                        </Button>
                    </Box>
                </Grid>

                {state.Data && (
                    <Grid item xs={12}>
                        <Card>
                            <CardHeader
                                title="Select SMS"
                                subheader={props.Activefield1}
                            />
                            <CardContent>
                                <ListCheckboxMoveUpDown
                                    Data={state.Data}
                                    title="SMS List"
                                    ShowUpDown={false}
                                    ShowEdit={false}
                                    // AddBox={(e) => props.AddBox(e)}
                                    DefaultChecked={state.SelectedSMS || []}
                                    // NextLoadComponenet={props.NextLoadComponenet}
                                    Category="SelectSMS"
                                    SelectedDataReturn={(e) => KeyArray(e)}
                                    PerPage={10}
                                />

                            </CardContent>
                        </Card>
                    </Grid>
                )}

                <Grid item xs={12}>
                    <Card>
                        <CardHeader
                            title="Introduction / Invitation Video"
                            subheader={props.Activefield1}
                        />
                        <CardContent>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Video Name"
                                        name="VideoName"
                                        value={state.VideoName || ""}
                                        fullWidth
                                        variant="outlined"
                                        onChange={(e) => SetValues(e.target.value, "VideoName")}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Link to Introduction Video"
                                        name="IntroVideo"
                                        value={state.IntroVideo || ""}
                                        fullWidth
                                        variant="outlined"
                                        onChange={(e) => SetValues(e.target.value, "IntroVideo")}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={() => AddVideo()}
                                    >
                                        Add Video
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h5">
                                        Select the Introduction Videos from the list
                                        </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    {state.IntroVideoList.length > 0 && (
                                        <ListCheckboxMoveUpDown
                                            Data={state.IntroVideoList}
                                            title="SMS List"
                                            ShowUpDown={false}
                                            ShowEdit={false}
                                            // AddBox={(e) => props.AddBox(e)}
                                            DefaultChecked={state.SelectedVideo || []}
                                            // NextLoadComponenet={props.NextLoadComponenet}
                                            Category="SelectSMS"
                                            SelectedDataReturn={(e) => AddVideoArray(e)}
                                            PerPage={10}
                                        />
                                    )}
                                </Grid>
                                
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12}>
                    <Box
                        display="flex"
                        justifyContent="flex-end"
                    // sx={{ m: 1 }}
                    >
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => SaveData()}
                        >
                            Save Selections
                        </Button>
                    </Box>
                </Grid>



            </Grid>

            <DrawerShow
                LoadComponenet="ItemAddPage"
                CloseDrawer={() => CloseDrawer()}
                Category="AddSMS"
                propss={state.DetailsProps}
                DrawerStatus={DrawerStatus}
                OpenDrawer={(e) => OpenDrawer(e)}
                anchor={state.anchor || "left"}
            />

            <Backdrop open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Snackbar
                open={state.OpenAlert}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                message={state.AlarmMessage}
            />

        </>
    );
};

export default ListItems;
