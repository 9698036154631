import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { createStore, applyMiddleware, combineReducers } from "redux";
//import { createBrowserHistory } from "history";
//import { connectRouter } from "connected-react-router";
//import thunk from "redux-thunk";
import { Provider } from "react-redux";
import * as serviceWorker from './serviceWorker';
import App from './App';
import RootReducer from './RootReducer'
//import ContractorSignup from './MainWebsite/ContractorSignUpWiz'

const store = createStore(RootReducer);

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
);

//const SignUpContainer = document.getElementById('root');
//const SignUpContainerroot = createRoot(SignUpContainer); // createRoot(container!) if you use TypeScript
//SignUpContainerroot.render(<ContractorSignup />);

//const rootNode = document.getElementById('SignUpContainerroot');
//const SignUproot = ReactDOM.createRoot(rootNode);
//SignUproot.render(React.createElement(ContractorSignup));


//ReactDOM.render((
//  <Provider store={store}>
//  <BrowserRouter>
//    <App />
//    </BrowserRouter>
//  </Provider>
//), document.getElementById('root'));

/*
ReactDOM.createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
)
*/
serviceWorker.unregister();
